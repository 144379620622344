import React, { useEffect } from "react";
import { generateGuid } from "./kendo_common";
import $ from "jquery";
// var imageBrowser = {
//   enable: true,
//   read: url,
//   upload: url,
//   delete: url,
//   image: url,
//   thumbnail: url,
// };

export const KendoEditor = ({
  name,
  imageBrowser,
  height,
  value,
  required,
  onValidate,
  onCursorPositionChange,
  onChange,
  refreshWhen,
}) => {
  var guid = generateGuid();

  useEffect(() => {
    if (!$(`#${guid}-${name}`).data("kendoEditor")) {
      $(`#${guid}-${name}`).kendoEditor({
        tools: [
          "bold",
          "italic",
          "underline",
          "undo",
          "redo",
          "strikethrough",
          "justifyLeft",
          "justifyCenter",
          "justifyRight",
          "justifyFull",
          "insertUnorderedList",
          "insertOrderedList",
          "insertUpperRomanList",
          "insertLowerRomanList",
          "indent",
          "outdent",
          "subscript",
          "superscript",
          "tableWizard",
          "createTable",
          "addRowAbove",
          "addRowBelow",
          "addColumnLeft",
          "addColumnRight",
          "deleteRow",
          "deleteColumn",
          "mergeCellsHorizontally",
          "mergeCellsVertically",
          "splitCellHorizontally",
          "splitCellVertically",
          "tableAlignLeft",
          "tableAlignCenter",
          "tableAlignRight",
          "formatting",
          "cleanFormatting",
          "copyFormat",
          "applyFormat",
          "fontSize",
          "foreColor",
          "backColor",
          "print",
        ],
        imageBrowser: imageBrowser &&
          imageBrowser.enable && {
            messages: {
              dropFilesHere: "Drop files here",
            },
            transport: {
              read: imageBrowser.read,
              destroy: {
                url: imageBrowser.delete,
                type: "POST",
              },
              create: {
                url: "",
                type: "POST",
              },
              thumbnailUrl: imageBrowser.thumbnail,
              uploadUrl: imageBrowser.upload,
              imageUrl: imageBrowser.image,
            },
          },
        select: function (e) {
          $("#hdn-" + name).val(this.value());
          if (required && onValidate) {
            onValidate(name, this.value());
          }
          if (required && !onValidate) {
            console.error(`function onValidate missing for field "${name}"`);
          }
          if (onCursorPositionChange) {
            onCursorPositionChange(e.sender.getSelection().focusOffset);
          }

          if (onChange) {
            onChange(this.value());
          }
        },
        keyup: function (e) {
          $("#hdn-" + name).val(this.value());
          if (required && onValidate) {
            onValidate(name, this.value());
          }
          if (required && !onValidate) {
            console.error(`function onValidate missing for field "${name}"`);
          }

          if (onChange) {
            onChange(this.value());
          }
        },
        value: value || "",
      });
    }
  }, []);

  useEffect(() => {
    $(`#${guid}-${name}`).data("kendoEditor").value(value);
    $("#hdn-" + name).val(value);
  }, [value, refreshWhen]);

  return (
    <div className="k-editor-div">
      <textarea
        id={"hdn-" + name}
        name={name}
        style={{ display: "none" }}
        data-required={required}
        defaultValue={value || ""}
      ></textarea>
      <textarea
        id={`${guid}-${name}`}
        rows={10}
        cols={30}
        style={{ height: height }}
      ></textarea>
    </div>
  );
};
