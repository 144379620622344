import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function CatagoryBar({ categories }) {
  const location = useLocation();

  let selectedCategoryId;

  if (location.pathname.includes("/PenTalkDetails")) {
    selectedCategoryId = location.pathname.split("/")[2];
  } else {
    selectedCategoryId = location.pathname.split("/").pop();
  }

  const setVisible = () => {
    const scrollToTopButton = document.getElementById("scrollToTopButton");
    scrollToTopButton.click();
  };
  const totalBlogsCount = categories.reduce(
    (sum, category) => sum + (category.blogsCount || 0),
    0
  );

  return (
    <div className="sidebar">
      <div className="widget Label" data-version={2} id="Label3">
        <div className="widget-title">
          <h4 className="title">CATEGORIES</h4>
        </div>
        <div
          className={`widget-content list-label  ${
            selectedCategoryId === "PenTalk" ? "categorylist-selected" : ""
          }`}
        >
          <ul>
            <li key="0">
              <Link
                className={`label-name ${
                  selectedCategoryId === "PenTalk" ? "selected-category" : ""
                }`}
                to={`/PenTalk`}
                onClick={() => setVisible}
              >
                ALL
                <span className="label-count">({totalBlogsCount})</span>
              </Link>
            </li>
            {categories.map((category) => (
              <li key={category.categoryId}>
                <Link
                  className={`label-name ${
                    selectedCategoryId === category.categoryId.toString()
                      ? "selected-category"
                      : ""
                  }`}
                  to={`/PenTalkCategory/${category.categoryId}`}
                  onClick={setVisible}
                >
                  {category.categoryName.toUpperCase()}{" "}
                  <span className="label-count">({category.blogsCount})</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
