import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; 
import Loader from "../Common/Loader"; 
import BreadcrumbHeader from "../Common/BreadcrumbHeader";
import Scroll from "../Common/Scroll";
import Footer from "../Common/Footer";
import CatagoryBar from "./CatagoryBar";
import AxiosServices from "../../Services/AxiosServices";

const PenTalkDetails = () => {
  const { category, id } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
 
  const axiosService = new AxiosServices();

  const fetchCategoriesAndBlogs = async () => {
    try {
      const categoryResponse = await axiosService.get(
        "PenTalk/get-categories/0"
      );
      setCategories(categoryResponse.data);

      const blogResponse = await axiosService.get(`PenTalk/get-blogs/${id}`);
      const filteredBlog = blogResponse.data.find(
        (blog) =>
          blog.blogId === parseInt(id) && blog.categoryId === parseInt(category)
      );
      setBlog(filteredBlog);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategoriesAndBlogs();
  }, [id]);

  if (loading) {
    return <Loader />;
  }
  if (!blog) {
    return (
      <>
        <p>Blog not found</p>
      </>
    );
  }

  return (
    <div>
      <BreadcrumbHeader />
      <div className="container topMargin">
        <div className="row">
          <div className="col-md-8 blogcontent">
            <div
              key={blog.blogId}
              className="section-blog-title topTextPenTalk"
            >
              <h2>{blog.title}</h2>
              <p>
                Posted on{" "}
                {kendo.toString(new Date(blog.updatedDate), "MMMM dd yyyy ")} |{" "}
                {
                  categories.find(
                    (category) => category.categoryId === blog.categoryId
                  )?.categoryName
                }
              </p>
              <span
                dangerouslySetInnerHTML={{
                  __html: blog.content,
                }}
              />
            </div>
          </div>
          <div className="col-md-4 categorybar">
            <CatagoryBar categories={categories} />
          </div>
        </div>
      </div>
      {/* <WithoutFooter /> */}
      <Scroll />
      <Footer>
        <p style={{ textAlign: "center", margin: "20px 0" }}>
          &copy; 2024 Your Company. All rights reserved.
        </p>
      </Footer>
    </div>
  );
};

export default PenTalkDetails;
