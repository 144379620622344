import React, { useState, useEffect } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
const TextInput = ({
  type,
  name,
  value,
  onChange,
  label,
  resetPasswordVisibility,
  onBlur,
}) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  useEffect(() => {
    if (resetPasswordVisibility) {
      setPasswordVisible(false);
    }
  }, [resetPasswordVisibility]);

  return (
    <>
      <label className="form-label">
        {label}
        <small className="text-danger">*</small>
      </label>
      <div className="position-relative">
        <input
          type={isPasswordVisible ? "text" : type}
          name={name}
          id={name}
          value={value}
          onChange={onChange}
          className="form-control"
          onBlur={onBlur}
        />
        {type === "password" && (
          <span className="eye-icon" onClick={togglePasswordVisibility}>
            {isPasswordVisible ? (
              <FaEye color="#5b8c68" />
            ) : (
              <FaEyeSlash color="#5b8c68" />
            )}
          </span>
        )}
      </div>
    </>
  );
};

export default TextInput;
