import React from "react";
import { KendoEditor } from "../../../../Kendo/kendo_editor";
import TextInput from "../TextInput/TextInput";
import Button from "../Button/Button";
export default function AddBlogModal({
  blog,
  handleCloseModal,
  handleChange,
  handlekendochange,
  handleSubmit,
  editIndex,
  titleChange,
}) {
  return (
    <div
      className="modal fade Mymodal"
      id="addBlogModal"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div
        className="modal-dialog modal-lg " // Updated to modal-x
        role="document"
      >
        <div className="modal-content ">
          <div className="modal-header">
            <h5 className="modal-title">Add Blog</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={handleCloseModal}
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <section className="contact-area">
              <div className="row fadeInUp">
                <form className="contact-form" autoComplete="off">
                  <div className="col-md-12">
                    <div className="contact-form">
                      <div className="row">
                        <div className={"form-group col-md-12"}>
                          <TextInput
                            type={"text"}
                            onChange={handleChange}
                            value={blog.title}
                            name="title"
                            label="Title"
                            onBlur={titleChange}
                          />
                        </div>
                        <div className={`form-group col-md-12`}>
                          <label>
                            Description
                            <span className="text-danger">*</span>
                          </label>

                          <KendoEditor
                            name={"description"}
                            height={300}
                            value={blog.content}
                            onChange={(e) => handlekendochange(e)}
                          />

                          <span className="text-danger" />
                        </div>
                        <div className="col-md-12 text-right">
                          <Button
                            name={"Cancel"}
                            onClick={handleCloseModal}
                            dismiss={"modal"}
                          />
                          <Button
                            name={editIndex !== null ? "Update" : "Submit"}
                            onClick={handleSubmit}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
