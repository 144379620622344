import React, { useEffect } from "react";
import { generateGuid } from "./kendo_common";
import $ from "jquery";

export const KendoDropdown = ({
  name,
  url,
  data,
  dataTextField,
  dataValueField,
  value,
  required,
  searchable,
  placeholder,
  onValidate,
  onChange,
  isReadOnly,
}) => {
  const guid = generateGuid();

  useEffect(() => {
    var dropdown = $(`#${guid}-${name}`).data("kendoDropDownList");
    if (dropdown) {
      dropdown.destroy();
    }

    $(`#${guid}-${name}`)
      .kendoDropDownList({
        filter: searchable && "contains",
        dataTextField: dataTextField,
        dataValueField: dataValueField,
        optionLabel: placeholder || "",
        change: function (e) {
          var selValue = this.value();
          $("#hdn-" + name).val(selValue);
          if (onChange) {
            onChange(selValue);
          }
          if (required && onValidate) {
            onValidate(name, $("#hdn-" + name).val());
          }
          if (required && !onValidate) {
            console.error(`function onValidate missing for field "${name}"`);
          }
        },
        value: value,
        dataSource: url
          ? {
              transport: {
                read: {
                  url: url,
                },
              },
            }
          : data || [],
      })
      .data("kendoDropDownList")
      .setOptions({
        fillMode: "outline",
      });
  }, [data]);

  useEffect(() => {
    let dropdown = $(`#${guid}-${name}`).data("kendoDropDownList");
    dropdown.enable(isReadOnly);
  }, [isReadOnly]);

  useEffect(() => {
    if (value != null && value != undefined) {
      var Select = $(`#${guid}-${name}`).data("kendoDropDownList");
      Select.value(value);
      $(`#hdn-${name}`).val(value);
    }
  }, [value]);

  return (
    <>
      <input
        type="text"
        id={"hdn-" + name}
        name={name}
        defaultValue={value || ""}
        data-required={required || false}
        style={{ display: "none" }}
      />
      <input id={`${guid}-${name}`} />
    </>
  );
};

export const getKendoDropdownVal = ({ id }) => {
  return $(id).data("kendoDropDownList").value();
};
