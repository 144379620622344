import React, { useEffect, useState } from "react";
import BreadcrumbHeader from "../Common/BreadcrumbHeader";
import Footer from "../Common/Footer";
import Loader from "../Common/Loader";
import ContactServices from "../../Services/ContactServices";
import Swal from "sweetalert2";
import Scroll from "../Common/Scroll";
import Button from "../Admin/AdminPenTalk/Button/Button";
// import { ToastContainer, toast } from "react-toastify";
var ContactServicesObj = new ContactServices();
const Connectwithus = () => {
  const [error, setError] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    helptext: "",
    messages: "",
  });
  const [contactDetails, setContactDetails] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      $(".preloader").css("display", "none");
    }, 500);
  }, []);

  const onSubmit = () => {
    debugger;

    let isValid = true;
    let isEmailValid = true;

    var data = {
      name: document.getElementById("name").value.trim(),
      email: document.getElementById("email").value.trim(),
      phone: document.getElementById("phone").value.trim(),
      companyName: document.getElementById("companyName").value.trim(),
      helptext: document.getElementById("helptext").value.trim(),
      messages: document.getElementById("messages").value.trim(),
    };

    const setErrorState = (elementId, hasError) => {
      const element = document.getElementById(elementId);
      if (element) {
        if (hasError) {
          element.style.border = "1px solid #dd3535"; // Add red border
          isValid = false;
        } else {
          element.style.border = "";
        }
      } else {
        console.error(`Element with ID '${elementId}' not found`);
      }
    };

    setErrorState("name", data.name === "");
    setErrorState("email", data.email === "");
    setErrorState("phone", data.phoneNumber === "");
    setErrorState("companyName", data.companyName === "");
    var emailcheck = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      data.email
    );
    if (emailcheck && ("email", data.email != "")) {
      isEmailValid = false;
      Swal.fire({
        icon: "warning",
        title: "Enter valid email address",
        position: "top-end",
        toast: true,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        customClass: {
          container: "custom-swal-container",
        },
      });
    }
    if (isValid && isEmailValid) {
      setIsLoaded(true);
      ContactServicesObj.Create(data).then((res) => {
        if (res.data === 1) {
          debugger;
          Swal.fire({
            icon: "success",
            title: "Send Successfully",
            position: "top-end",
            toast: true,
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            customClass: {
              container: "custom-swal-container",
            },
          });
          setIsLoaded(false);

          document.getElementById("name").value = "";
          document.getElementById("email").value = "";
          document.getElementById("phone").value = "";
          document.getElementById("companyName").value = "";
          document.getElementById("helptext").value = "";
          document.getElementById("messages").value = "";
        } else if (res.data === 0) {
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            position: "top-end",
            toast: true,
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            customClass: {
              container: "custom-swal-container",
            },
          });
          setIsLoaded(false);
          document.getElementById("name").value = "";
          document.getElementById("email").value = "";
          document.getElementById("phone").value = "";
          document.getElementById("companyName").value = "";
          document.getElementById("helptext").value = "";
          document.getElementById("messages").value = "";
        }
      });
    } else if (!isValid) {
      Swal.fire({
        icon: "error",
        title: "Please fill the mandatory fields",
        position: "top-end",
        toast: true,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        customClass: {
          container: "custom-swal-container",
        },
      });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setContactDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function CheckEmailChange(e) {
    debugger;
    var email = e.target.value;
    var emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z]{2,}$/i;

    if (email === "") {
      // myerror.
      setError({ ...error, email: "Email is Required" });
      //myerror.email = "Email is Required";
      // $("#emailError").text("Email is Reqiured");
    } else if (!emailRegex.test(email)) {
      setError({ ...error, email: "Invalid Email Address" });
      // myerror.email = "Invalid Email Address";
      // $("#emailError").text("Invalid Email Address");
    } else {
      setError({ ...error, email: "" });
      // $("#emailError").text("");
    }
  }

  function CantactNumberChange(e) {
    const inputValue = e.target.value;
    // Replace all characters that are not digits, plus sign, or parentheses
    // const sanitizedValue = inputValue.replace(/[^\d+()]/g, "");
    const sanitizedValue = inputValue.replace(/[^\d]/g, "");

    // Update the input value with the sanitized value
    e.target.value = sanitizedValue;
  }
  function nameChange() {
    var FirstName = $("#name").val();
    if (FirstName == "") {
      document.getElementById("name").style.border = "1px solid #dd3535";
    } else {
      document.getElementById("name").style.border = "";
    }
  }
  function ContactNumber() {
    var FirstName = $("#phone").val();
    if (FirstName == "") {
      document.getElementById("phone").style.border = "1px solid #dd3535";
    } else {
      document.getElementById("phone").style.border = "";
    }
  }
  function CompanyChange() {
    var FirstName = $("#companyName").val();
    if (FirstName == "") {
      document.getElementById("companyName").style.border = "1px solid #dd3535";
    } else {
      document.getElementById("companyName").style.border = "";
    }
  }

  return (
    <>
      <Loader />
      <BreadcrumbHeader />
      <section className="topMargin2" id="contact">
        <div className="container ">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-title ">
                <h2>CONNECT WITH US</h2>
              </div>
              <p>Let's get in touch</p>
              <p>
                We're eager to discuss your projects, challenges, or any other
                inquiries.
              </p>
              <p>
                PEN Software is your partner in success. Whether you seek
                expertise in Digital Transformation, Oracle and SAP Consulting,
                Professional Staffing, or Custom IT Solutions, count on us to
                propel your projects forward.
              </p>
            </div>
          </div>
          <div className="col-sm-12 mt-5">
            <div className="row contact-form-design-area wow fadeInUp">
              {/* START CONTACT FORM DESIGN AREA */}

              <div className="row">
                <div className="contact-form">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>
                          Name<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          id="name"
                          onChange={handleChange}
                          onBlur={nameChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          Email<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="email"
                          className="form-control"
                          id="email"
                          onChange={handleChange}
                          // onBlur={CheckEmailChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          Country Code and Phone Number
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="phone"
                          className="form-control"
                          id="phone"
                          maxLength={16}
                          // onKeyDown={onKeyDown}
                          onChange={handleChange}
                          onInput={CantactNumberChange}
                          onBlur={ContactNumber}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          Company<span className="text-danger">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          name="companyName"
                          className="form-control"
                          id="companyName"
                          onChange={handleChange}
                          onBlur={CompanyChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>How can our team help ? </label>
                        <input
                          type="text"
                          name="helptext"
                          className="form-control"
                          id="helptext"
                          onChange={handleChange}
                        />
                      </div>

                      <div className="form-group col-md-12">
                        <label>
                          Any other details you would like to share with us at
                          this stage
                        </label>
                        <textarea
                          rows={6}
                          name="messages"
                          className="form-control"
                          id="messages"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-12 text-center">
                        {!isLoaded ? (
                          <>
                            {/* <button type="button" onClick={() => onSubmit()} name="submit" id="submit">
                          Send Message
                        </button> */}

                            {/* <button onClick={() => onSubmit()}>
                              Send Message
                            </button> */}                    
                        <Button onClick={onSubmit} name={"Send Message"}/>
                          </>
                        ) : (
                          <button
                            className="btn btn-primary"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Loading...
                          </button>
                        )}
                        {/* <button type="submit" name="submit" id="submit">Send Message</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* /END  CONTACT DETAILS DESIGN AREA */}
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Scroll />
    </>
  );
};
export default Connectwithus;
