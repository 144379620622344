import React from "react";
import AxiosServices from "./AxiosServices";

const Auth = new AxiosServices();

class AuthenticationService {
  getData = async (authentication) => {
    return Auth.post("/Login/Authenticate", authentication);
  };

  getOtpData = async (userName) => {
    return Auth.get(`/Login/OTPPassword?userName=${userName}`);
  };

  forgotpassword = async (user) => {
    return Auth.post("/Login/ForgetPassword", user);
  };

  verifyotp = async (data) => {
    return Auth.post("/Login/ForgotpasswordCheckOtp", data);
  };

  ChangepasswordFromOtp = async (data) => {
    // return Auth.post('/Login/ChangePasswordFromOTP', data)
    return Auth.post("/Login/ChangePasswordFromOTP", data);
  };

  Changepassword = async (data) => {
    return Auth.post("/Login/ChangePassword", data);
  };
}

export default AuthenticationService;
