import React from "react";
import TextInput from "../AdminPenTalk/TextInput/TextInput";
import Button from "../AdminPenTalk/Button/Button";

export default function ChangePasswordModal({
  passwords,
  handleCloseModal,
  handlePasswordChange,
  handleSubmit,
  resetPasswordVisibility
}) {
  return (
    <div
      className="modal fade Mymodal"
      id="changepasswordmodal"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div
        className="modal-dialog change-password-modal-dialog modal-sm changpasswordmodal"
        role="document"
      >
        <div className="modal-content change-password-modal-content">
          <div className="modal-header change-password-modal-header">
            <h5 className="modal-title ">Change Password</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={handleCloseModal}
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body change-password-modal-body">
            <section className="contact-area">
              <div className="row fadeInUp">
                <form className="contact-form" autoComplete="off">
                  <div className="col-md-12">
                    <div className="contact-form">
                      <div className="row">
                        <div className="form-group col-md-12">
                          <TextInput
                            type="password"
                            onChange={handlePasswordChange}
                            value={passwords.oldPassword}
                            resetPasswordVisibility={resetPasswordVisibility}
                            name="oldPassword"
                            label="Old Password"
                          />
                        </div>
                        <div className="form-group col-md-12">
                          <TextInput
                            type="password"
                            onChange={handlePasswordChange}
                            value={passwords.newPassword}
                            resetPasswordVisibility={resetPasswordVisibility}
                            name="newPassword"
                            label="New Password"
                          />
                        </div>
                        <div className="form-group col-md-12">
                          <TextInput
                            type="password"
                            onChange={handlePasswordChange}
                            value={passwords.confirmPassword}
                            resetPasswordVisibility={resetPasswordVisibility}
                            name="confirmPassword"
                            label="Confirm Password"
                          />
                        </div>
                        <div className="col-md-12 text-right">
                          <Button
                            name="Cancel"
                            onClick={handleCloseModal}
                            dismiss="modal"
                          />
                          <Button name="Change" onClick={handleSubmit} />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
