import React from "react";
import Button from "../../AdminPenTalk/Button/Button";

export default function DeleteOpportunityModal({ confirmDelete }) {
  return (
    <div
      className="modal fade Mymodal"
      id="OpportunityDeleteModal"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-sm" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Delete Opportunity</h5>
            <button
              type="button"
              id="oppourtunitiesModelClsBtn"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <section className="contact-area">
              <div className="row  fadeInUp">
                <form className="contact-form" autoComplete="off">
                  <div className="col-md-12">
                    <div className="contact-form">
                      <div className="row">
                        <div className="form-group col-md-12">
                          <label>
                            Are you sure you want to delete this entry?
                          </label>
                        </div>
                        <div className="col-md-12 text-center">
                          <Button name={"Cancel"} dismiss={"modal"} />
                          &nbsp;
                          <Button name={"Delete"} onClick={confirmDelete} />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
