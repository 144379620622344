import React from "react";
import PropTypes from "prop-types";

const JobDetailsModal = ({ opportunity }) => {
  if (!opportunity) return null;

  return (
    <div>
      <div className="job-title">{opportunity.position}</div>
      <div className="job-location">{opportunity.location}</div>
      <div className="job-type">{opportunity.empType}</div>
      <p>
        <b>Work Place Type:</b>
        {opportunity.workType}
      </p>
      <p>
        <b>Contract Term:</b> {opportunity.contractTerm}
      </p>
      <p>
        <b>Summary:</b> <span>{opportunity.summary}</span>
      </p>
      <p>
        <b>Indicative Experience:</b> {opportunity.experience}
      </p>
      <p>
        <b>Required Skills for the position:</b>
      </p>
      <ul className="Oppo-skills">{opportunity.requiredSkills}</ul>
    </div>
  );
};

JobDetailsModal.propTypes = {
  opportunity: PropTypes.shape({
    opportunityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    position: PropTypes.string.isRequired,
    location: PropTypes.string,
    empType: PropTypes.string,
    workType: PropTypes.string,
    contractTerm: PropTypes.string,
    summary: PropTypes.string,
    experience: PropTypes.string,
    requiredSkills: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default JobDetailsModal;

// import React from "react";
// import PropTypes from "prop-types";

// const JobDetailsModal = () => {
//   return (
//     <div>
//       <div className="job-title">Senior Java Developer</div>
//       <div className="job-location">Dallas, Texas, USA</div>
//       <div className="job-type">Contract</div>
//       <p>
//         <b>Position:</b> Senior Java Developer
//       </p>
//       <p>
//         <b>Work Location:</b> Dallas , Texas, USA
//       </p>
//       <p>
//         <b>Work Place Type:</b> Remote
//       </p>
//       <p>
//         <b>Employment Type:</b> Contract
//       </p>
//       <p>
//         <b>Contract Term:</b> 12 months (+ Further Extension possible)
//       </p>
//       <p>
//         <b>Summary:</b>
//         <span>
//           As a Senior Java Developer, you will be responsible for Full Stack
//           Java development projects for PEN Software’s clients on a contract
//           basis.{" "}
//         </span>
//       </p>
//       <p>
//         <b>Indicative Experience:</b> 7 years + in Java Tech Stack
//       </p>
//       <p>
//         <b>Required Skills for the position:</b>
//       </p>
//       <p className="Oppo-skills">
//         • Full Stack Java Developer with experience in Spring Boot, Micro
//         Services, Scripting, Angular or React JS
//       </p>
//       <p className="Oppo-skills">
//         • Experience in NodeJS, Java EE/Spring Framework preferred
//       </p>
//       <p className="Oppo-skills">
//         • Experience with Databases like Oracle and Cassandra
//       </p>
//       <p className="Oppo-skills">
//         • Exposure to AWS architecture, concepts, and tools.
//       </p>
//       <p className="Oppo-skills">• Dev Ops knowledge - Jenkins, Jira</p>
//     </div>
//   );
// };

// JobDetailsModal.propTypes = {
//   job: PropTypes.shape({
//     position: PropTypes.string,
//     location: PropTypes.string,
//     workPlaceType: PropTypes.string,
//     employmentType: PropTypes.string,
//     contractTerm: PropTypes.string,
//     summary: PropTypes.string,
//     experience: PropTypes.string,
//     skills: PropTypes.arrayOf(PropTypes.string),
//   }),
// };

// export default JobDetailsModal;
