import React from "react";
import TextInput from "../../AdminPenTalk/TextInput/TextInput";
import Button from "../../AdminPenTalk/Button/Button";
import { KendoDropdown } from "../../../../Kendo/kendo_dropdown";
import { KendoEditor } from "../../../../Kendo/kendo_editor";

const AddOpportunityModal = ({
  opportunity,
  handleCloseModal,
  errors,
  handleChange,
  handlekendochange,
  handleWorktypechange,
  handleTextareachange,
  handleEmptypechange,
  handleSubmit,
  editIndex,
}) => {
  return (
    <div
      className="modal fade Mymodal "
      id="addopportunitymodal"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content ">
          <div className="modal-header">
            <h5 className="modal-title">Add Opportunity</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={handleCloseModal}
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <section className="contact-area">
              <div className="row fadeInUp">
                <form className="contact-form" autoComplete="off">
                  <div className="col-md-12">
                    <div className="contact-form">
                      <div className="row">
                        <div className={"form-group col-md-6"}>
                          <TextInput
                            type={"text"}
                            name="position"
                            value={opportunity.position}
                            onChange={handleChange}
                            label="Position"
                          />
                        </div>
                        {/* <div className={"form-group col-md-6"}>
                          <TextInput
                            type={"text"}
                            name="location"
                            value={opportunity.location}
                            onChange={handleChange}
                            label="Location"
                          />
                        </div> */}
                        <div className="form-group col-md-6">
                          <label>
                            Work Place Type
                            <span className="text-danger">*</span>
                          </label>
                          <div
                            className="kendo-dropdown-wrapper"
                            style={{
                              border: errors.workType ? "1px solid red" : "",
                              borderRadius: errors.workType ? "8px" : "",
                            }}
                          >
                            <KendoDropdown
                              name="workType"
                              data={[
                                { id: " ", value: " " },
                                { id: "On-Site", value: "On-Site" },
                                { id: "Off-Site", value: "Off-Site" },
                                { id: "Remote", value: "Remote" },
                              ]}
                              dataTextField="value"
                              dataValueField="id"
                              value={opportunity.workType}
                              onChange={handleWorktypechange}
                              className="form-control worktype"
                            />
                          </div>
                        </div>
                        <div className="form-group col-md-6">
                          <label>
                            Employment Type
                            <span className="text-danger">*</span>
                          </label>
                          <div
                            className="kendo-dropdown-wrapper"
                            style={{
                              border: errors.empType ? "1px solid red" : "",
                              borderRadius: errors.empType ? "8px" : "",
                            }}
                          >
                            <KendoDropdown
                              name="empType"
                              data={[
                                { id: " ", value: " " },
                                { id: "Full-time", value: "Full-time" },
                                { id: "Part-time", value: "Part-time" },
                                { id: "Internship", value: "Internship" },
                                { id: "Contract", value: "Contract" },
                              ]}
                              dataTextField="value"
                              dataValueField="id"
                              value={opportunity.empType}
                              onChange={handleEmptypechange}
                              className="form-control emptype"
                            />
                          </div>
                        </div>

                        <div className={"form-group col-md-6"}>
                          <TextInput
                            type={"text"}
                            name="contractTerm"
                            value={opportunity.contractTerm}
                            onChange={handleChange}
                            label="Contract Term"
                          />
                        </div>
                        <div className={"form-group col-md-6"}>
                          <TextInput
                            type={"text"}
                            name="experience"
                            value={opportunity.experience}
                            onChange={handleChange}
                            label="Experience"
                            className="form-control"
                          />
                        </div>
                        <div className={`form-group col-md-12`}>
                          <label>
                            Location
                            <span className="text-danger">*</span>
                          </label>
                          <textarea
                            name={"location"}
                            id="location"
                            style={{
                              height: "90px",
                              whiteSpace: "pre-wrap",
                            }}
                            className="textarea"
                            value={opportunity.location}
                            onChange={handleTextareachange}
                          />
                          <span className="text-danger" />
                        </div>
                        <div className={`form-group col-md-12`}>
                          <label>
                            Summary
                            <span className="text-danger">*</span>
                          </label>
                          <textarea
                            name={"summary"}
                            id="summary"
                            style={{ height: "120px", whiteSpace: "pre-wrap" }}
                            className="textarea"
                            value={opportunity.summary}
                            onChange={handleTextareachange}
                          />
                          <span className="text-danger" />
                        </div>
                        <div className={`form-group col-md-12`}>
                          <label>
                            Required Skills
                            <span className="text-danger">*</span>
                          </label>
                          <KendoEditor
                            name={"requiredSkills"}
                            height={300}
                            value={opportunity.requiredSkills}
                            onChange={(e) => handlekendochange(e)}
                          />
                          <span className="text-danger" />
                        </div>
                        <div className="col-md-12 text-right">
                          <Button
                            name={"Cancel"}
                            onClick={handleCloseModal}
                            dismiss={"modal"}
                          />
                          <Button
                            name={editIndex !== null ? "Update" : "Submit"}
                            onClick={handleSubmit}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOpportunityModal;
