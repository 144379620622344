import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "./Changepass.css";
import ChangePasswordModal from "./ChangePasswordModal";
import AuthenticationService from "../../../Services/LoginService";
import { DeleteAllCookie } from "../../../common";

const auth = new AuthenticationService();

export const Layout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [resetPasswordVisibility, setResetPasswordVisibility] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }

    const oldPw = document.getElementById(name);
    if (!value.trim()) {
      oldPw.style.border = "1px solid #dd3535";
    } else {
      oldPw.style.border = "";
    }
  };

  const handleCloseModal = () => {
    setResetPasswordVisibility(true);
    setTimeout(() => setResetPasswordVisibility(false), 0);

    const oldPw = document.getElementById("oldPassword");
    if (oldPw) {
      oldPw.style.border = "";
    }
    const newPw = document.getElementById("newPassword");
    if (newPw) {
      newPw.style.border = "";
    }
    const confirmPw = document.getElementById("confirmPassword");
    if (confirmPw) {
      confirmPw.style.border = "";
    }

    setPasswords({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setErrors({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { oldPassword, newPassword, confirmPassword } = passwords;
    const newErrors = {};

    if (!oldPassword) {
      newErrors.oldPassword = "Please enter your current password.";
      const current = document.getElementById("oldPassword");
      current.style.border = "1px solid #dd3535";
    }
    if (!newPassword) {
      newErrors.newPassword = "New password is required.";
      const current = document.getElementById("newPassword");
      current.style.border = "1px solid #dd3535";
    } else if (newPassword.length < 6) {
      const current = document.getElementById("newPassword");
      current.style.border = "1px solid #dd3535";
      newErrors.newPassword = "New password must be at least 6 characters.";
    }
    if (newPassword === oldPassword) {
      const current = document.getElementById("oldPassword");
      current.style.border = "1px solid #dd3535";
      newErrors.confirmPassword = "Old Password and New Password cant be same.";
    }
    if (newPassword !== confirmPassword) {
      const current = document.getElementById("confirmPassword");
      current.style.border = "1px solid #dd3535";
      newErrors.confirmPassword = "Passwords do not match.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      Swal.fire({
        icon: "error",
        title: "Error",
        position: "top-end",
        toast: true,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        text: Object.values(newErrors).join(" "),
      });
      return;
    }

    try {
      const response = await auth.Changepassword({
        userID: 1,
        oldPassword,
        newPassword,
        confirmPassword,
      });

      if (response && response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Password changed successfully",
          position: "top-end",
          toast: true,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        $("#changepasswordmodal").modal("hide");
        handleCloseModal();
      } else {
        const oldPw = document.getElementById("oldPassword");
        if (oldPw) {
          oldPw.style.border = "1px solid #dd3535";
        }
        Swal.fire({
          icon: "error",
          title: "Error",
          position: "top-end",
          text: "Unable to change password. Please try again.",
        });
      }
    } catch (error) {
      const errorMsg =
        error.response?.data?.errorCode === "INCORRECT_CURRENT_PASSWORD"
          ? "The current password is incorrect."
          : "The current password is incorrect. Please try again.";
      setErrors({ oldPassword: errorMsg });

      const oldPw = document.getElementById("oldPassword");
      if (oldPw) {
        oldPw.style.border = "1px solid #dd3535";
      }

      Swal.fire({
        icon: "error",
        title: "Error",
        position: "top-end",
        text: errorMsg,
        toast: true,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    }
  };

  return (
    <div className="adminHeader">
      <div className="header-top-area">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <div className="logo">
                <a href="/">
                  <img
                    className="LogoImg"
                    src="/assets/images/pensoftLogowhite.png"
                    alt="Pensoft Logo"
                  />
                </a>
              </div>
            </div>

            <div className="col-sm-9">
              <div className="mainmenu mainmenuPadding">
                <div className="navbar-header">
                  <h1
                    className="admin-text"
                    style={{
                      position: "absolute",
                      top: "-42px",
                      right: "56px",
                    }}
                  >
                    Admin
                  </h1>

                  <button
                    type="button"
                    className="navbar-toggle"
                    onClick={toggleMenu}
                  >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                </div>

                <div
                  className={`navbar-collapse collapse ${
                    isMenuOpen ? "in" : ""
                  }`}
                  ref={menuRef}
                  style={{ width: "100%" }}
                >
                  <ul className="nav navbar-nav m-0">
                    <li>
                      <Link to="/Opportunities-admin" className="smoth-scroll">
                        OPPORTUNITIES
                      </Link>
                    </li>
                    <li>
                      <Link to="/AdminPenTalk" className="smoth-scroll">
                        PEN TALK
                      </Link>
                    </li>

                    <li className="visible-xs">
                      <a
                        data-toggle="modal"
                        data-target="#changepasswordmodal"
                        style={{ cursor: "pointer" }}
                      >
                        Change Password
                      </a>
                    </li>
                    <li className="visible-xs">
                      <a
                        href="javascript:void(0);"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          DeleteAllCookie();
                          window.location.href = "/admin";
                        }}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                  <ul className="nav mob-admin-menu navbar-nav navbar-right">
                    <li className="dropdown">
                      <a
                        href="#"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                      >
                        Admin <span className="caret" />
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            data-toggle="modal"
                            data-target="#changepasswordmodal"
                            style={{ cursor: "pointer" }}
                          >
                            Change Password
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              DeleteAllCookie();
                              window.location.href = "/admin";
                            }}
                          >
                            Logout
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ChangePasswordModal
        passwords={passwords}
        handleCloseModal={handleCloseModal}
        handlePasswordChange={handlePasswordChange}
        handleSubmit={handleSubmit}
        errors={errors}
        resetPasswordVisibility={resetPasswordVisibility}
      />
    </div>
  );
};
