import React from "react";

export default function Button({ id, name, onClick, target, dismiss }) {
  return (
    <>
      {name.toLowerCase() === "cancel" ? (
        <a
          className="read-more1"
          id={id}
          onClick={onClick}
          data-dismiss={dismiss}
        >
          {name}
        </a>
      ) : (
        <a
          className="apply-now"
          id={id}
          onClick={onClick}
          data-toggle={target && "modal"}
          data-target={target}
        >
          {name}
        </a>
      )}
    </>
  );
}
