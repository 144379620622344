import React, { useEffect, useState } from "react";
import ApplyOppourtunities from "./ApplyOppourtunities";
import JobDetailsModal from "./JobDetailsModal";
import JavaJobDetails from "./JavaJobDetails";
import Loader from "../Common/Loader.js";
import AxiosServices from "../../Services/AxiosServices.js";
import BreadcrumbHeader from "../Common/BreadcrumbHeader.js";
import Button from "../Admin/AdminPenTalk/Button/Button.js";
const OppourtunitiesBody = () => {
  const [text, setText] = useState("");
  const [visibleOpportunities, setVisibleOpportunities] = useState(4);
  const [opportunityData, setOpportunityData] = useState([]);
  const [opportunity, setOpportunity] = useState(null);
  const [loading, setLoading] = useState(true);
  const axiosService = new AxiosServices();

  const fetchOppourtunities = async () => {
    try {
      const opportunityResponse = await axiosService.get(
        "Oppurtunities/get-oppourtunities/0"
      );
      setOpportunityData(
        opportunityResponse.data.sort(
          (a, b) => b.opportunitiesId - a.opportunitiesId
        )
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchOppourtunities();
  }, []);

  const loadMoreOpportunity = () => {
    const newStart = visibleOpportunities;
    setVisibleOpportunities((prev) => prev + 4);
    fetchOppourtunities(newStart);
  };
  function setBreak(location) {
    return (location ?? "")
      .split("\n")
      .map((line, index) => (index === 0 ? line : [<br key={index} />, line]));
  }

  return (
    <>
      {loading && <Loader />}
      <BreadcrumbHeader />
      <section id="team" className="topMargin">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="single-about">
                <div className="section-title">
                  <h2>Join Us and Shape the Future of IT</h2>
                </div>
                <p>Are you ready to be a game-changer in the IT world?</p>
              </div>
              <p className="text-black">
                At PEN Software, we don’t just adapt to change; we create it.
                Our mission is to revolutionize businesses through our
                cutting-edge expertise in Digital Transformation, Oracle and SAP
                Consulting, Professional Staffing, and Custom IT Solutions.
              </p>
              <p className="text-black">
                We're all about people and relationships. If you're passionate
                and want to make a real impact in a dynamic, inclusive
                environment, join us.
              </p>
              <p className="text-black">Ready to Make a Difference?</p>
              <p className="text-black">
                E-mail us at{" "}
                <a href="mailto:hr@pensoftware.io" className="email-link">
                  hr@pensoftware.io{" "}
                </a>
                and let’s start the conversation!
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="team">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-blog-title">
                <h2>Current Openings</h2>
                {opportunityData.length === 0 ? (
                  <p
                    className="text-center"
                    style={{
                      marginTop: "12px",
                      color: "gray",
                      fontSize: "16px",
                    }}
                  >
                    No Opportunities
                  </p>
                ) : (
                  <>
                    {loading ? (
                      <p>Loading opportunities...</p>
                    ) : (
                      opportunityData
                        .slice(0, visibleOpportunities)
                        .map((opportunity) => (
                          <div
                            key={opportunity.opportunityId}
                            className="job-listing"
                          >
                            <div className="row">
                              <div className="col-sm-9">
                                <div className="job-title">
                                  {opportunity.position}
                                </div>
                                <div className="job-location">
                                  {setBreak(opportunity.location)}
                                </div>
                                <div className="job-type">
                                  {opportunity.type}
                                </div>
                                <div className="read-more-model">
                                  <Button
                                    target="#jobDetailsModal"
                                    onClick={() => setOpportunity(opportunity)}
                                    name={"Read More"}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-3">
                                <Button
                                  target="#addRowModal"
                                  onClick={() => setText(opportunity.position)}
                                  name={"Apply Now"}
                                />
                              </div>
                            </div>
                          </div>
                        ))
                    )}
                  </>
                )}
                {visibleOpportunities < opportunityData.length && (
                  <div className="loadmoreblogs">
                    <Button onClick={loadMoreOpportunity} name={"Load More"} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal fade"
        id="jobDetailsModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div
          className="modal-dialog modal-lg"
          style={{ marginTop: "116px" }}
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Job Description</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <JobDetailsModal opportunity={opportunity} />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="javaJobDetailsModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div
          className="modal-dialog modal-lg"
          style={{ marginTop: "116px" }}
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Job Description</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <JavaJobDetails opportunity={opportunity} />
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="addRowModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div
          class="modal-dialog modal-lg"
          style={{ marginTop: "85px" }}
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Apply Opportunities</h5>
              <button
                type="button"
                id="oppourtunitiesModelClsBtn"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <ApplyOppourtunities text={text} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OppourtunitiesBody;
