import React, { useState, useEffect } from "react";
import Button from "../AdminPenTalk/Button/Button.js";
import { KendoGrid } from "../../../Kendo/kendo_grid.js";
import Swal from "sweetalert2";
import Loader from "../../Common/Loader.js";
import AxiosServices from "../../../Services/AxiosServices.js";
import DeleteOpportunityModal from "./Modal/DeleteOpportunityModal.js";
import AddOpportunityModal from "./Modal/AddOpportunityModal.js";
import { Layout } from "../Layout/Layout.js";

const OpportunitiesAdmin = () => {
  const [opportunity, setOpportunity] = useState({
    opportunitiesId: "",
    position: "",
    location: "",
    workType: "",
    empType: "",
    contractTerm: "",
    summary: "",
    experience: "",
    requiredSkills: "",
  });

  const [opportunities, setOpportunities] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [errors, setErrors] = useState({
    opportunityId: "",
    position: "",
    location: "",
    workType: "",
    empType: "",
    contractTerm: "",
    summary: "",
    experience: "",
    requiredSkills: "",
  });
  const [loading, setLoading] = useState(true);
  const axiosService = new AxiosServices();

  const fetchOppourtunities = async () => {
    try {
      const opportunityResponse = await axiosService.get(
        "Oppurtunities/get-oppourtunities/0"
      );
      setOpportunities(
        opportunityResponse.data.sort(
          (a, b) => b.opportunitiesId - a.opportunitiesId
        )
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchOppourtunities();
  }, []);
  const validateForm = () => {
    const validationErrors = {
      opportunityId: "",
      position: "",
      location: "",
      workType: "",
      empType: "",
      contractTerm: "",
      summary: "",
      experience: "",
      requiredSkills: "",
    };
    let isValid = true;

    const checkField = (field, fieldName) => {
      if (!field || !field.trim()) {
        validationErrors[fieldName] = `${fieldName} is required.`;
        return false;
      }
      return true;
    };

    if (!checkField(opportunity.position, "position")) {
      const editorDiv = document.getElementById("position");
      if (editorDiv) {
        editorDiv.style.border = "1px solid #dd3535";
      }
      isValid = false;
    }
    if (!checkField(opportunity.location, "location")) {
      const editorDiv = document.getElementById("location");
      if (editorDiv) {
        editorDiv.style.border = "1px solid #dd3535";
      }
      isValid = false;
    }
    if (!checkField(opportunity.workType, "workType")) {
      const editorDiv = document.getElementsByClassName("worktype")[0];
      if (editorDiv) {
        editorDiv.style.border = "1px solid #dd3535";
      }
      isValid = false;
    }
    if (!checkField(opportunity.empType, "empType")) {
      const editorDiv = document.getElementsByClassName("emptype")[1];
      if (editorDiv) {
        editorDiv.style.border = "1px solid #dd3535";
      }
      isValid = false;
    }
    if (!checkField(opportunity.contractTerm, "contractTerm")) {
      const editorDiv = document.getElementById("contractTerm");
      if (editorDiv) {
        editorDiv.style.border = "1px solid #dd3535";
      }
      isValid = false;
    }
    if (!checkField(opportunity.experience, "experience")) {
      const editorDiv = document.getElementById("experience");
      if (editorDiv) {
        editorDiv.style.border = "1px solid #dd3535";
      }
      isValid = false;
    }
    if (!checkField(opportunity.summary, "summary")) {
      const editorDiv = document.getElementById("summary");
      if (editorDiv) {
        editorDiv.style.border = "1px solid #dd3535";
      }
      isValid = false;
    }
    if (!checkField(opportunity.requiredSkills, "requiredSkills")) {
      const editorDiv = document.getElementsByClassName("k-editor")[0];
      if (editorDiv) {
        editorDiv.style.border = "1px solid #dd3535";
      }
      isValid = false;
    }

    if (!isValid) {
      Swal.fire({
        icon: "error",
        title: "Please fill the mandatory fields",
        position: "top-end",
        toast: true,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        customClass: { container: "custom-swal-container" },
      });
    }

    setErrors(validationErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOpportunity((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    const inputDiv = document.getElementById(name);
    if (inputDiv && value.trim()) {
      inputDiv.style.border = "";
    } else {
      inputDiv.style.border = "1px solid #dd3535";
    }
  };

  const handleWorktypechange = (e) => {
    setOpportunity((prevState) => ({
      ...prevState,
      workType: e,
    }));
    console.log(e);
    if (e === " ") {
      setErrors({ ...errors, workType: "requird" });
    } else {
      setErrors({ ...errors, workType: "" });
    }
  };

  const handleEmptypechange = (e) => {
    setOpportunity((prevState) => ({
      ...prevState,
      empType: e,
    }));

    if (e === " ") {
      setErrors({ ...errors, empType: "requird" });
    } else {
      setErrors({ ...errors, empType: "" });
    }
  };

  const handleTextareachange = (e) => {
    const { name, value } = e.target;
    setOpportunity((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    const inputDiv = document.getElementById(name);
    if (inputDiv && value.trim()) {
      inputDiv.style.border = "";
    } else {
      inputDiv.style.border = "1px solid #dd3535";
    }
  };

  const handlekendochange = (e) => {
    setOpportunity((prevState) => ({
      ...prevState,
      requiredSkills: e,
    }));

    const editorDiv = document.getElementsByClassName("k-editor")[0];
    if (e.trim()) {
      editorDiv.style.border = "";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm() === false) {
      return;
    }
    const savedBy = "1";
    const opportunityData = {
      ...opportunity,
      opportunitiesId: "0",
    };
    try {
      if (editIndex !== null) {
        await axiosService.post(
          `Oppurtunities/save-oppourtunities?savedBy=${savedBy}`,
          opportunity
        );
        fetchOppourtunities();
        Swal.fire({
          icon: "success",
          title: "Updated Successfully",
          position: "top-end",
          toast: true,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          customClass: { container: "custom-swal-container" },
        });
      } else {
        const response = await axiosService.post(
          `Oppurtunities/save-oppourtunities?savedBy=${savedBy}`,
          opportunityData
        );
        fetchOppourtunities();

        Swal.fire({
          icon: "success",
          title: "Added Successfully",
          position: "top-end",
          toast: true,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          customClass: { container: "custom-swal-container" },
        });
      }
      $("#addopportunitymodal").modal("hide");
      handleCloseModal();
    } catch (error) {
      console.error("Error saving opportunity:", error);
      Swal.fire({
        icon: "error",
        title: "Failed to save opportunity",
        position: "top-end",
        toast: true,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    }
  };

  const handleEditClick = (selectedOpportunity) => {
    setOpportunity({
      opportunitiesId: selectedOpportunity.opportunitiesId,
      position: selectedOpportunity.position,
      location: selectedOpportunity.location,
      workType: selectedOpportunity.workType,
      empType: selectedOpportunity.empType,
      contractTerm: selectedOpportunity.contractTerm,
      summary: selectedOpportunity.summary,
      experience: selectedOpportunity.experience,
      requiredSkills: selectedOpportunity.requiredSkills,
    });
    console.log(selectedOpportunity);

    setEditIndex(selectedOpportunity.opportunitiesId);
    $("#addopportunitymodal").modal("show");
  };

  const handleDeleteClick = (opportunity) => {
    setDeleteIndex(opportunity.opportunitiesId);
    $("#OpportunityDeleteModal").modal("show");
  };

  const confirmDelete = async () => {
    if (deleteIndex !== null) {
      try {
        const deletedBy = "1";
        await axiosService.get(
          `Oppurtunities/delete-oppourtunities/${deleteIndex}?deletedBy=${deletedBy}`
        );
        setOpportunities((prevOpportunities) =>
          prevOpportunities.filter(
            (opportunity) => opportunity.opportunityId !== deleteIndex
          )
        );
        fetchOppourtunities();
        Swal.fire({
          icon: "success",
          title: "Deleted Successfully",
          position: "top-end",
          toast: true,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          customClass: { container: "custom-swal-container" },
        });
      } catch (error) {
        console.error("Error deleting blog:", error);
        Swal.fire({
          icon: "error",
          title: "Failed to delete blog",
          position: "top-end",
          toast: true,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      setDeleteIndex(null);
      $("#OpportunityDeleteModal").modal("hide");
    }
  };

  const handleCloseModal = () => {
    setOpportunity({
      opportunityId: "",
      position: "",
      location: "",
      workType: "",
      empType: "",
      contractTerm: "",
      summary: "",
      experience: "",
      requiredSkills: "",
    });

    const editorDiv = document.getElementsByClassName("k-editor")[0];
    if (editorDiv) {
      editorDiv.style.border = "";
    }

    const position = document.getElementById("position");
    if (position) {
      position.style.border = "";
    }

    const location = document.getElementById("location");
    if (location) {
      location.style.border = "";
    }

    const contractTerm = document.getElementById("contractTerm");
    if (contractTerm) {
      contractTerm.style.border = "";
    }

    const experience = document.getElementById("experience");
    if (experience) {
      experience.style.border = "";
    }

    const summary = document.getElementById("summary");
    if (summary) {
      summary.style.border = "";
    }
    const selectDiv = document.getElementsByClassName("worktype")[0];
    if (selectDiv) {
      selectDiv.style.border = "";
    }
    const emptype = document.getElementsByClassName("emptype")[0];
    if (emptype) {
      emptype.style.border = "";
    }

    setEditIndex(null);

    setErrors({
      opportunityId: "",
      position: "",
      location: "",
      workType: "",
      empType: "",
      contractTerm: "",
      summary: "",
      experience: "",
      requiredSkills: "",
    });
  };

  return (
    <>
      <Layout />
      <div className="container topMargin">
        {loading && <Loader />}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div className="section-title">
            <h2 style={{ marginTop: "30px" }}>OPPORTUNITIES</h2>
          </div>
          <div>
            <Button name={"Add Opportunity"} target={"#addopportunitymodal"} />
          </div>
        </div>

        <div style={{ marginTop: "30px" }}>
          <KendoGrid
            name="grd_Opportunities"
            data={opportunities}
            schema={{
              position: { type: "string" },
              location: { type: "string" },
              workType: { type: "string" },
              empType: { type: "string" },
              contractTerm: { type: "string" },
              experience: { type: "string" },
              summary: { type: "string" },
              requiredSkills: { type: "string" },
            }}
            columns={[
              {
                field: "opportunitiesId",
                title: "Id",
                hidden: true,
              },
              {
                field: "position",
                title: "Position",
                width: "200px",
              },
              {
                field: "location",
                template: function (e) {
                  return (e.location ?? "").replaceAll("\n", "<br/>");
                },
                title: "Location",
                width: "300px",
              },
              {
                field: "workType",
                title: "Work Place Type",
                width: "170px",
              },
              {
                field: "empType",
                title: "Employment Type",
                width: "190px",
              },
              {
                field: "contractTerm",
                title: "Contract Term",
                width: "200px",
              },
              {
                field: "experience",
                title: "Experience",
                width: "200px",
              },
              {
                template: function (e) {
                  return (e.summary ?? "").replaceAll("\n", "<br/>");
                },
                field: "summary",
                title: "Summary",
                width: "300px",
              },
              {
                field: "requiredSkills",
                title: "Required Skills",
                width: "400px",
                hidden: true,
              },
            ]}
            filterable={true}
            pageable={true}
            sortable={true}
            showOptions={true}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            scrollable={true}
            exportFileName={"Opportunities.xlsx"}
          />
        </div>

        <AddOpportunityModal
          opportunity={opportunity}
          errors={errors}
          editIndex={editIndex}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleCloseModal={handleCloseModal}
          handlekendochange={handlekendochange}
          handleWorktypechange={handleWorktypechange}
          handleTextareachange={handleTextareachange}
          handleEmptypechange={handleEmptypechange}
        />
        <DeleteOpportunityModal confirmDelete={confirmDelete} />
      </div>
    </>
  );
};
export default OpportunitiesAdmin;
